import React from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../layouts/Landing';
import Product from '../routes/Product';

interface ProductEnPageProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                tagline: string;
            };
        };
    };
}

const productEn = ({
    data: {
        location,
        site: {
            siteMetadata: { name },
        },
    },
}: ProductEnPageProps) => {
    return (
        <LandingLayout lang="en" location={location}>
            <Product lang="en" />
        </LandingLayout>
    );
};

export const productEnPageQuery = graphql`
    query productEnPageQuery {
        site {
            siteMetadata {
                name
                tagline
            }
        }
    }
`;

export default productEn;
